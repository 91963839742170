import React from "react"
import styled from "styled-components"
// modules
import useBlog from "../../modules/use_blog"
// components
import Layout from "../../components/layout"
import TitleLeft from "../../components/molecules/title_left"
import Posts from "../../components/molecules/posts"
import SEO from "../../components/seo"

const StyledBlog = styled.div`
  background: #e8ecef;
  display: grid;
  grid-column-end: -1;
  grid-column-start: 1;
  grid-template-columns: inherit;
  grid-template-rows: inherit;
  padding: 15px 0;
`

const Wrapper = styled.div`
  background: #ffffff;
  box-sizing: border-box;
  grid-column-end: -2;
  grid-column-start: 2;
  min-height: calc(100vh - 169px);
  overflow: hidden;
  padding: 25px;

  @media (max-width: 767px) {
    min-height: calc(100vh - 137px);
  }
`

const Blog = ({ location }) => {
  const posts = useBlog()
  return (
    <Layout location={location}>
      <StyledBlog>
        <Wrapper>
          <TitleLeft
            level={1}
            fontSize="20px"
            heading="施工事例"
          />
          <Posts
            posts={posts}
            slug="case"
          />
        </Wrapper>
      </StyledBlog>
    </Layout>
  )
}

export default Blog

export const Head = ({ location }) => (
  <SEO
    title="施工事例 | ブログ"
    description="株式会社マルトモのブログページです。"
    pathname={location.pathname}
  />
)
